// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-clubpage-jsx": () => import("./../../../src/templates/Clubpage.jsx" /* webpackChunkName: "component---src-templates-clubpage-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-district-page-jsx": () => import("./../../../src/templates/DistrictPage.jsx" /* webpackChunkName: "component---src-templates-district-page-jsx" */),
  "component---src-templates-post-page-jsx": () => import("./../../../src/templates/PostPage.jsx" /* webpackChunkName: "component---src-templates-post-page-jsx" */)
}

